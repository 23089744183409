import 'ol/ol.css';
import MVT from 'ol/format/MVT';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import Popup from 'ol/Overlay'
import Geocoder from 'ol-geocoder'
import {boundingExtent} from 'ol/extent';
import TileLayer from 'ol/layer/Tile';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import MousePosition from 'ol/control/MousePosition';
import View from 'ol/View';
import {transformExtent, useGeographic} from 'ol/proj';
import {createStringXY} from 'ol/coordinate';
import {defaults as defaultControls} from 'ol/control';
import {shadowLayer, styleSelector} from './style_rules.js';

const viewport = document.getElementById('map');
import {Fill, Stroke, Style, Text} from 'ol/style';

const initialZoom = 11;
const mapMaxZoom = 20;

useGeographic();

const mapExtent = boundingExtent([[1.45, 1.5], [2, 2.5]])

const view = new View({
  center: [1.8, 1.8],
  zoom: 11,
  minZoom: initialZoom
});

const mapSource = new VectorTileSource({
    format: new MVT(),
    url: 'nightcity/{z}/{x}/{y}.mvt',
    attributions: '© <a href="https://www.openstreetmap.org/">OpenStreetMap</a>',
})

const basemap = new Map({
  layers: [
    new VectorTileLayer({
        declutter: false,
        source: mapSource,
        style: styleSelector,
        background: '#000000'
    })
  ],
  target: 'map',
  view: view,
});

function getMinZoom() {
  return initialZoom;
}
