import {Fill, Stroke, Style, Text} from 'ol/style.js';
import RegularShape from 'ol/style/RegularShape.js';
import { toGeometry } from 'ol/render/Feature';
/* PC98 */
// Background
//Black
const C0 = '#000000';
//Red
const C1 = '#aa0000';
//Dark Green 1
const C2 = '#0e2528';
//Light Yellow
const C3 = '#f8d48f';
//Dark Green 2
const C4 = '#123432';
//Light Grey 1
const C5 = '#3d484b';
const C6 = '';
//VFD Fade
const C7 = '#22aa91';
//Foreground
const C8 = '#555555';
//Blink Red
const C9 = '#ff5555';
//Light Green 1
const CA = '#05363d';
//Yellow
const CB = '#f5e4c3';
//Light Green 2
const CC = '#1e5855';
//Light Grey 1
const CD = '#9a9a9a';
const CE = '';
//VFD Green
const CF = '#17ffa0';

/* W95
const C0 = '#000000';
const C1 = '#aa0000';
const C2 = '#00aa00';
const C3 = '#aa5500';
const C4 = '#0000aa';
const C5 = '#aa00aa';
const C6 = '#00aaaa';
const C7 = '#aaaaaa';

const C8 = '#555555';
const C9 = '#ff5555';
const CA = '#55ff55';
const CB = '#ffff55';
const CC = '#5555ff';
const CD = '#ff55ff';
const CE = '#55ffff';
const CF = '#ffffff';
*/
const W95GRN = '#208c71';
const W95RED = '#e35f5f';
const W95YEL = '#dfe300';
const W95BLU = '#394dcd';
const W95BLK = '#6c6c6c';


/*
AMBER
const C0 = '#000000';
const C1 = '#551400';
const C2 = '#792400';
const C3 = '#862c00';
const C4 = '#ae4900';
const C5 = '#be5500';
const C6 = '#e37100';
const C7 = '#ef7900';

const C8 = '#b24d00';
const C9 = '#cb5d00';
const CA = '#eb7900';
const CB = '#f37d00';
const CC = '#ff9e04';
const CD = '#ffaa10';
const CE = '#ffdb30';
const CF = '#ffe334';
*/

const textStyleLabelFill = new Fill({
    color: CF
});

const textStyleLabelBackgroundFill = new Fill({
    color: C0
});


const shadow = new Style({
    zIndex: 0,
    fill: new Fill({
        color: C2
    })
})

function strokeSizedFunction(resolution, maxsize=3, factor=9) {
    return Math.min(Math.floor(factor/resolution), maxsize);
}

function selectStyleRoad(feature, resolution) {
    const roadStyleFill = new Fill({
      color: C3
    });
    const roadStyleStroke = new Stroke({
      color: CB,
      width: strokeSizedFunction(resolution, 36, 6)
    });
    const textStyleLabelStroke = new Stroke({
        color: C7,
        width: strokeSizedFunction(resolution, 2),
        lineJoin: 'round',
    });
    const textStyleLabelBackgroundStroke = new Stroke({
        color: CD,
        width: strokeSizedFunction(resolution, 2)
    });
    var label_text = feature.get('name')
    if (typeof label_text !== 'undefined') {
        label_text = label_text.toUpperCase();
    }
    const buildingStyleLabelText = new Text({
        text: label_text,
        font: '10px LCD14',
        stroke: textStyleLabelStroke,
        fill: textStyleLabelFill,
        backgroundFill: textStyleLabelBackgroundFill,
        backgroundStroke: textStyleLabelBackgroundStroke,
        zIndex: 3
    });

    const roadStyle = new Style({
        fill: roadStyleFill,
        stroke: roadStyleStroke,
        text: buildingStyleLabelText,
        zIndex: 1
    });

    return roadStyle;
}

function selectStyleBuilding(feature, resolution) {
    const buildingStyleFill = new Fill({
        color: CA,
        opacity: 1.0
    });
    const buildingStyleStroke = new Stroke({
       color: CC,
       width: strokeSizedFunction(resolution),
       lineJoin: 'bevel',
       lineCap: null
    });
    const textStyleLabelStroke = new Stroke({
        color: C7,
        width: strokeSizedFunction(resolution, 2),
        lineJoin: 'round',
    });
    const textStyleLabelBackgroundStroke = new Stroke({
        color: CD,
        width: strokeSizedFunction(resolution, 2)
    });
    var buildingStyleLabelText = null;
    if (resolution < 1 && typeof feature.get('name') !== 'undefined') {
        var label_text = feature.get('name').toUpperCase();
        buildingStyleLabelText = new Text({
            text: label_text,
            font: '10px LCD14',
            stroke: textStyleLabelStroke,
            fill: textStyleLabelFill,
            backgroundFill: textStyleLabelBackgroundFill,
            backgroundStroke: textStyleLabelBackgroundStroke,
            zIndex: 3,
            rotation: -0.05,
            overflow: true,
            repeat: null,
            textAlign: 'center',
            placement: 'point',
            declutter: 'none'
        });
    };

    const buildingStyle = new Style({
        fill: buildingStyleFill,
        stroke: buildingStyleStroke,
        zIndex: 2,
        text: buildingStyleLabelText,
    });
    return buildingStyle;
}

function setShadowLayer(feature, resolution) {
    var shadowFactorX = Math.min(Math.max(5 * resolution, 2), 7);
    var shadowFactorY = Math.min(Math.max(-7.5 * resolution, -10), -2);

    const shadowGeometry = toGeometry(feature).clone();
    shadowGeometry.translate(shadowFactorX, shadowFactorY);
    shadow['stroke'] = new Stroke({
        color: C2,
        width: strokeSizedFunction(resolution, 9),
        lineJoin: 'bevel',
        lineCap: null
    })
    return shadowGeometry;
}

function styleSelector(feature, resolution) {
    if(feature.get('building')) {
        const shadowGeometry = setShadowLayer(feature, resolution);
        shadow.setGeometry(shadowGeometry)
        return [shadow, selectStyleBuilding(feature, resolution)];
    } else if (feature.get('highway')) {
        return selectStyleRoad(feature, resolution);
    }
}

function shadowLayer(feature, resolution) {
    if(feature.get('building')) {
        return new Style({
            fill: new Fill({
                color: C2
            })
        })
    }
}

export {shadowLayer, styleSelector};